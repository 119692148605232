/*
Template Name: Sipyo -  Admin & Dashboard Template
Author: Themesdesign
Version: 1.3.0
Contact: themesdesign.in@gmail.com
File: Main Css File
*/

/*Fonts */
@import "custom/fonts/fonts";

/*Core files */
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins.scss";

/* Structure */
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

/* Components */
@import "custom/components/root";
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/forms";
@import "custom/components/demos";
@import "custom/components/print";

/* Plugins */
@import "custom/plugins/apexcharts";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/calendar";
@import "custom/plugins/color-picker";
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/datatable";
@import "custom/plugins/datepicker";
@import "custom/plugins/flot";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/google-map";
@import "custom/plugins/parsley";
@import "custom/plugins/range-slider";
@import "custom/plugins/rating";
@import "custom/plugins/responsive-table";
@import "custom/plugins/select2";
@import "custom/plugins/session-timeout";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/switch";
@import "custom/plugins/table-editable";
@import "custom/plugins/vector-maps";
@import "custom/plugins/x-editable";

/* Pages */
@import "custom/pages/authentication";
@import "custom/pages/email";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Medium.ttf') format('truetype');
}

@keyframes shadowPulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(199, 35, 40, 0.7);
        -moz-box-shadow: 0 0 0 0 rgba(199, 35, 40, 0.7);
        box-shadow: 0 0 0 0 rgba(199, 35, 40, 0.7);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px transparent;
        -moz-box-shadow: 0 0 0 10px transparent;
        box-shadow: 0 0 0 10px transparent;
    }

    100% {
        -webkit-box-shadow: 0 0 0 0px transparent;
        -moz-box-shadow: 0 0 0 0px transparent;
        box-shadow: 0 0 0 0px transparent;
    }
}

* {
    font-family: 'Gilroy', sans-serif;
}

input[type='checkbox'] {
    accent-color: #c72328;
    width: 16px;
    height: 16px;
}

.text-primary {
    color: #c72328 !important;
}

.anticon-question-circle {
    font-size: 19.5px;
}

.bg-transparent {
    background-color: transparent !important;
}

li.mm-active {
    background-color: #252425;
    border-radius: 0.375rem !important;
}

.my-bottom-sheet > div {
    z-index: 1600;
}

[data-rsbs-header]:before {
    width: 200px;
    height: 8px;
}

.mt-4-5 {
    margin-top: 2rem;
}

.ant-btn {
    font-weight: 550 !important;
    font-size: 1.09375rem !important;

    display: flex;
    justify-content: center;
    align-items: center;
}

.sipyo-border {
    border: 0.1px solid !important;
    border-color: #d9d9d9 !important;
}

.sipyo-border-top {
    border-top: 0.1px solid !important;
    border-color: #d9d9d9 !important;
}

.sipyo-border-bottom {
    border-bottom: 0.1px solid !important;
    border-color: #d9d9d9 !important;
}

.sipyo-border-left {
    border-left: 0.1px solid !important;
    border-color: #d9d9d9 !important;
}

.sipyo-border-right {
    border-right: 0.1px solid !important;
    border-color: #d9d9d9 !important;
}

.text-2 {
    font-weight: 550 !important;
    font-size: 19.5px !important;
}

.fs-5 {
    font-weight: 550;
}

.fs-6 {
    font-weight: 550;
}

.sipyo-title {
    font-size: 24px !important;
    font-weight: 700 !important;
}

.sipyo-alttitle {
    font-size: 21.5px !important;
    font-weight: 650 !important;
}

.sipyo-subtitle {
    font-size: 17.5px;
    font-weight: 550 !important;
}

.paragraph-2 {
    font-size: 16px;
    font-weight: 500;
}

.medium-checkbox .ant-checkbox-inner {
    width: 30px;
    height: 30px;
}

.rounded-checkbox .ant-checkbox-inner {
    border-radius: 100%;
}

.medium-radio {
    width: 30px;
    height: 30px;
}

.rounded-radio {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.discounted-price {
    content: "";
    left: 7px;
    top: 11px;
    position: relative;
    height: 1px;
    width: 60px;
    z-index: 1;
    background-color: #000000;
    transform: rotate(338deg);
}

.discounted-price-md {
    content: "";
    left: 3px;
    top: 12px;
    position: relative;
    height: 1px;
    width: 56px;
    z-index: 1;
    background-color: #000000;
    transform: rotate(338deg);
}

.discounted-price-sm {
    content: "";
    left: 0px;
    top: 9px;
    position: relative;
    height: 1px;
    width: 46px;
    z-index: 1;
    background-color: #000000;
    transform: rotate(338deg);
}

.pv-archiveText {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

input[type='radio']:checked {
    box-shadow: 0 0 0 2px #c72328;
    appearance: none;
    border-color: #c72328;
    border-radius: 30px;
}

input[type='radio']:after {
    width: 20px;
    height: 20px;
    border-radius: 30px;
    top: 5px;
    left: 5px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
}

input[type='radio']:checked:after {
    width: 20px;
    height: 20px;
    border-radius: 30px;
    top: 5px;
    left: 5px;
    position: relative;
    background-color: #c72328;
    content: '';
    display: inline-block;
    visibility: visible;
}

.order-modal-header {
    height: 90px;
    background: "#3a3a3a";
    border-bottom: "0px";
}

.order-detail-item {
    min-height: 40px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span:first-child {
        margin-left: 4px;
    }
    
    span:last-child {
        margin-right: 4px;
    }
}

.shadowPulse-radio {
    width: 30px;
    height: 30px;
    opacity: 1;
    border-radius: inherit;
    animation: shadowPulse 1s ease-out infinite;
    animation-delay: 1.2s;
}

.shadowPulse {
    opacity: 1;
    border-radius: inherit;
    animation: shadowPulse 1s ease-out infinite;
    animation-delay: 1.2s;
}

.shadowPulse-checkbox .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    opacity: 1;
    border-radius: inherit;
    animation: shadowPulse 1s ease-out infinite;
    animation-delay: 1.2s;
}

.shadowPulse-radio {
    opacity: 1;
    animation: shadowPulse 1s ease-out infinite;
    animation-delay: 1.2s;
}

/* ! Remove Button Splash */
.linkBtn {
    &:focus {
        :global .ant-wave {
            display: none;
        }
    }
}

/* ! Remove Scroll Bar */
*::-webkit-scrollbar {
    display: none;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/*.overflow-y-auto::-webkit-scrollbar {
    width: 12px !important;
}

.overflow-y-auto::-webkit-scrollbar-track {
    background: #f0f0f0 !important;
    border-radius: 10px !important;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
    background: #888 !important;
    border-radius: 10px !important;
    border: 2px solid #f0f0f0 !important;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}*/

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* ! Menu Management */
@media screen and (min-width: 937px) {
    .menuManagement {
        display: grid;
        grid-template-columns: 30% 70%;
    }
}

@media screen and (max-width: 936px) {
    .menuManagement {}
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
}

.rotate-icon {
    transition: transform 0.3s ease;
}

.rotate-icon.rotated {
    transform: rotate(180deg);
}

.btn-xxxl {
    font-size: 18px;
    line-height: 1.5;
    height: 55px;
    width: 95%;
    padding: 7px 15px;
    border-radius: 8px;
}

.item-selector .ant-checkbox {
    width: 21px !important;
    height: 21px !important;
}

.item-selector .ant-checkbox-inner {
    width: 21px !important;
    height: 21px !important;
}

.item-selector .ant-checkbox-wrapper {
    width: 21px !important;
    height: 21px !important;
}

.item-selector .ant-checkbox-wrapper .ant-checkbox {
    width: 21px !important;
    height: 21px !important;
}

.item-selector .ant-checkbox-wrapper .ant-checkbox-input {
    width: 21px !important;
    height: 21px !important;
}

.item-selector .ant-checkbox .ant-checkbox-inner {
    width: 21px !important;
    height: 21px !important;
}

.item-selector .ant-checkbox-wrapper .ant-checkbox-inner {
    width: 21px !important;
    height: 21px !important;
}

.modal-xxl {
    width: 1600px !important;
}

.btn-xxxl .ant-btn-icon {
    width: 45px;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-btn {
    font-weight: 500 !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    border-color: transparent !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border-color: transparent !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-switch.ant-switch-checked {
    background: #c72328 !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-checkbox-checked .ant-checkbox-inner {
    background-color: #c72328 !important;
    border-color: #c72328 !important;

}

:where(.css-dev-only-do-not-override-1k979oh).ant-input-group>.ant-input:first-child,
:where(.css-dev-only-do-not-override-1k979oh).ant-input-group .ant-input-group-addon:first-child {
    min-height: 40px !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    background-color: #c72328 !important;
    border-color: #c72328 !important;
    color: white !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #c72328 !important;
    border-color: #c72328 !important;
    color: white !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-1k979oh).ant-input-outlined:focus-within {
    border-color: #c72328 !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-1k979oh).ant-input-outlined:focus-within {
    box-shadow: none !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-input-outlined:hover {
    border-color: #c72328 !important;
}

:where(.css-1k979oh).ant-input-outlined:hover {
    border-color: #c72328 !important;
}

:where(.css-1k979oh).ant-input-outlined:focus,
:where(.css-1k979oh).ant-input-outlined:focus-within {
    border-color: #c72328 !important;
    box-shadow: none !important;
}

:where(.css-1k979oh).ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    background-color: #c72328 !important;
    border-color: #c72328 !important;
    color: white !important;
}

:where(.css-1k979oh).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #c72328 !important;
    border-color: #c72328 !important;
    color: white !important;
}

/* React-Select */
.css-t3ipsp-control:hover {
    border-color: #c72328 !important;
}

.css-srkxt6-control:hover {
    border-color: #c72328 !important;
}

.css-1hm4x9g-control:hover {
    border-color: #c72328 !important;
}

.css-1hm4x9g-control {
    border-color: #c72328 !important;
}

.css-d7l1ni-option {
    background-color: #ffa6a9 !important;
    color: white !important;
}

.css-d7l1ni-option:active {
    background-color: #ffa6a9 !important;
    color: white !important;
}

.css-tr4s17-option {
    background-color: #c72328 !important;
}

.ant-menu-item-selected {
    background-color: #c72328 !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected::after {
    border-bottom: 0px !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light.ant-menu-horizontal>.ant-menu-submenu:hover::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu:hover::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light.ant-menu-horizontal>.ant-menu-item-active::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-active::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-active::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-active::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light.ant-menu-horizontal>.ant-menu-item-open::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-open::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-open::after,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-open::after {
    border-bottom: 0px !important;
}

.ant-menu-overflow-item {
    border-bottom: 0px !important;
}

:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light.ant-menu-horizontal>.ant-menu-submenu:hover::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu:hover::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light.ant-menu-horizontal>.ant-menu-item-active::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-active::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-active::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-active::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light.ant-menu-horizontal>.ant-menu-item-open::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-open::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-open::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-open::after {
    border: none !important;
}

:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light.ant-menu-horizontal>.ant-menu-item::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light.ant-menu-horizontal>.ant-menu-submenu::after,
:where(.css-dev-only-do-not-override-1vf7zu1).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu::after {
    border: none !important;
}

/* ! Numpad */

.sc-htoDjs.qlSAn {
    display: none;
}